















import Alert from "@/components/Alert.vue"
import PipelineForm from "@/components/PipelineForm.vue"
import { useUser } from "@/store/user"

export default defineComponent({
  components: { Alert, PipelineForm },
  setup(_, { root }) {
    const user = useUser()

    watchEffect(() => {
      if (!user.isConnected) root.$router.replace("/login")
    })
  },
})
